import {
  Cloud,
  ComputeEmergencyOccupanciesOptions,
  DelayRule,
  HealthCenter,
  RorOptions,
  ViaTrajectoireOptions,
} from '@ambuliz/sabri-core';

export type HealthCenterConfig = {
  name: string;
  group?: string;
  autodispatchActivated: boolean;
  autoComputeOccupanciesActivated: boolean;
  fareGroupActivated: boolean;
  portersLimitLyingDown: number;
  portersLimitLyingDownBed: number;
  portersLimitSittingUp: number;
  portersLimitWalking: number;
  fareGroupTimeInterval: number;
  autodispatchAnticipation: number;
  finess?: string;
  rorOptions?: RorOptions;
  viaTrajectoireOptions?: ViaTrajectoireOptions;
  computeAnomaliesActivated?: boolean;
  bookedBedDelayRule?: DelayRule;
  computeEmergencyOccupancies?: ComputeEmergencyOccupanciesOptions;
  hasPediatricService?: boolean;
  autoComputeDischarge: boolean;
  location?: { latitude: number; longitude: number };
  patientTransportEnabled?: boolean;
  chatSupportEnabled?: boolean;
  thesaurusEnabled?: boolean;
};

export const getHealthCenterConfig = (healthCenter: HealthCenter): HealthCenterConfig => ({
  name: healthCenter.name,
  group: healthCenter.groups && healthCenter.groups.length > 0 ? healthCenter.groups[0].name : undefined,
  autodispatchActivated: healthCenter.autodispatchActivated,
  autoComputeOccupanciesActivated: healthCenter.autoComputeOccupanciesActivated,
  fareGroupActivated: healthCenter.fareGroupActivated,
  portersLimitLyingDown: healthCenter.portersLimitByFarePosition?.LYING_DOWN || 0,
  portersLimitLyingDownBed: healthCenter.portersLimitByFarePosition?.LYING_DOWN_BED || 0,
  portersLimitSittingUp: healthCenter.portersLimitByFarePosition?.SITTING_UP || 0,
  portersLimitWalking: healthCenter.portersLimitByFarePosition?.WALKING || 0,
  fareGroupTimeInterval: healthCenter.fareGroupTimeInterval || 0,
  autodispatchAnticipation: healthCenter.autodispatchTimeInMinutesAnticipation || 0,
  finess: healthCenter.finess || undefined,
  rorOptions: healthCenter.rorOptions || { isActivated: false, username: undefined, affiliatedRor: undefined },
  computeAnomaliesActivated: healthCenter.computeAnomaliesActivated || false,
  bookedBedDelayRule: healthCenter.bookedBedDelayRule,
  computeEmergencyOccupancies: healthCenter.computeEmergencyOccupancies,
  hasPediatricService: healthCenter.hasPediatricService,
  autoComputeDischarge: healthCenter.autoComputeDischarge || false,
  location: healthCenter.location
    ? { latitude: healthCenter.location.latitude, longitude: healthCenter.location.longitude }
    : undefined,
  viaTrajectoireOptions: healthCenter.viaTrajectoireOptions,
  patientTransportEnabled: healthCenter.patientTransportEnabled,
  chatSupportEnabled: healthCenter.chatSupportEnabled,
  thesaurusEnabled: healthCenter.thesaurusEnabled,
});

export const updateHealthCenterConfig = async (id: string, config: HealthCenterConfig) => {
  const rorOptions = {
    username: config.rorOptions?.username || undefined,
    affiliatedRor: config.rorOptions?.affiliatedRor || undefined,
    isActivated: config.rorOptions?.isActivated || false,
    receiverEmail: config.rorOptions?.receiverEmail || undefined,
  };
  await Cloud.updateHealthCenter({
    id,
    name: config.name,
    autodispatchActivated: config.autodispatchActivated,
    autoComputeOccupanciesActivated: config.autoComputeOccupanciesActivated,
    fareGroupActivated: config.fareGroupActivated,
    fareGroupTimeInterval: config.fareGroupTimeInterval,
    portersLimitByFarePositionLyingDownBed: config.portersLimitLyingDownBed,
    portersLimitByFarePositionLyingDown: config.portersLimitLyingDown,
    portersLimitByFarePositionSittingUp: config.portersLimitSittingUp,
    portersLimitByFarePositionWalking: config.portersLimitWalking,
    autodispatchTimeInMinutesAnticipation: config.autodispatchAnticipation,
    groups: config.group ? [{ name: config.group }] : undefined,
    finess: config.finess,
    rorOptions,
    computeAnomaliesActivated: config.computeAnomaliesActivated,
    bookedBedDelayRule: config.bookedBedDelayRule,
    computeEmergencyOccupancies: config.computeEmergencyOccupancies,
    hasPediatricService: config.hasPediatricService,
    autoComputeDischarge: config.autoComputeDischarge,
    location: config.location
      ? { latitude: config.location.latitude, longitude: config.location.longitude }
      : undefined,
    viaTrajectoireOptions: config.viaTrajectoireOptions,
    patientTransportEnabled: config.patientTransportEnabled,
    chatSupportEnabled: config.chatSupportEnabled,
    thesaurusEnabled: config.thesaurusEnabled,
  });
};

export const hasConfigChanged = (config: HealthCenterConfig, initialConfig: HealthCenterConfig) =>
  config.name !== initialConfig.name ||
  config.group !== initialConfig.group ||
  config.autodispatchActivated !== initialConfig.autodispatchActivated ||
  config.autoComputeOccupanciesActivated !== initialConfig.autoComputeOccupanciesActivated ||
  config.fareGroupActivated !== initialConfig.fareGroupActivated ||
  config.portersLimitLyingDown !== initialConfig.portersLimitLyingDown ||
  config.portersLimitLyingDownBed !== initialConfig.portersLimitLyingDownBed ||
  config.portersLimitSittingUp !== initialConfig.portersLimitSittingUp ||
  config.portersLimitWalking !== initialConfig.portersLimitWalking ||
  config.fareGroupTimeInterval !== initialConfig.fareGroupTimeInterval ||
  config.autodispatchAnticipation !== initialConfig.autodispatchAnticipation ||
  config.finess !== initialConfig.finess ||
  config.rorOptions !== initialConfig.rorOptions ||
  config.computeAnomaliesActivated !== initialConfig.computeAnomaliesActivated ||
  config.bookedBedDelayRule?.type !== initialConfig.bookedBedDelayRule?.type ||
  config.bookedBedDelayRule?.value !== initialConfig.bookedBedDelayRule?.value ||
  config.computeEmergencyOccupancies !== initialConfig.computeEmergencyOccupancies ||
  config.hasPediatricService !== initialConfig.hasPediatricService ||
  config.autoComputeDischarge !== initialConfig.autoComputeDischarge ||
  config.location?.latitude !== initialConfig.location?.latitude ||
  config.location?.longitude !== initialConfig.location?.longitude ||
  config.viaTrajectoireOptions !== initialConfig.viaTrajectoireOptions ||
  config.patientTransportEnabled !== initialConfig.patientTransportEnabled ||
  config.chatSupportEnabled !== initialConfig.chatSupportEnabled ||
  config.thesaurusEnabled !== initialConfig.thesaurusEnabled;
