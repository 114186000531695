import { MoreVert } from '@mui/icons-material';
import { Box, Drawer, Grid, IconButton, Menu, MenuItem, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { i18n } from 'common/locale';
import { formatDateTime } from 'common/utils';
import { useState } from 'react';
import UserPasswordDialog from 'superadmin/pages/UserManagement/components/UserPasswordDialog';
import { NormalizedUser, UserUpdateFormState } from 'superadmin/pages/UserManagement/context';
import UserForm from '../UserForm';

export type UserRowProps = NormalizedUser & {
  onUpdate: (user: UserUpdateFormState) => Promise<void>;
};

const UserRow: React.FC<UserRowProps> = ({
  objectId,
  firstName,
  lastName,
  username,
  role,
  unit,
  unitName,
  notificationConfigurations,
  navigationACL,
  units,
  isReadOnly,
  whitelistedIPs,
  lastConnectionAt,
  onUpdate,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openPasswordDialog, setOpenPasswordDialog] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Grid style={{ padding: '8px 0px' }} component={Paper} container>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item xs={2}>
            <Box paddingLeft={4}>
              <Typography variant="h5">{username}</Typography>
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{i18n[role]}</Typography>
          </Grid>
          <Grid item xs={2}>
            {firstName && lastName && <Typography variant="body2">{`${firstName} ${lastName}`}</Typography>}
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">{unitName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2">
              {lastConnectionAt ? formatDateTime(lastConnectionAt) : i18n.neverConnected}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Box paddingRight={4}>
              <Grid container alignItems="center" justifyContent="flex-end">
                <Grid item>
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClick(event);
                    }}
                    size="large"
                  >
                    <MoreVert />
                  </IconButton>
                  <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        setEditModalOpen(true);
                        handleClose();
                      }}
                    >
                      {i18n.updateProfile}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        setOpenPasswordDialog(true);
                        handleClose();
                      }}
                    >
                      {i18n.updatePassword}
                    </MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <UserPasswordDialog
        userId={objectId}
        username={username}
        role={role}
        open={openPasswordDialog}
        onClose={() => setOpenPasswordDialog(false)}
      ></UserPasswordDialog>
      <Drawer
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        anchor="right"
        classes={{ paper: classes.root }}
      >
        <UserForm
          onCancel={() => setEditModalOpen(false)}
          onUpdateSubmit={onUpdate}
          objectId={objectId}
          unit={unit}
          role={role}
          firstName={firstName}
          lastName={lastName}
          notificationConfigurations={notificationConfigurations}
          navigationACL={navigationACL}
          units={units}
          isReadOnly={isReadOnly}
          whitelistedIPs={whitelistedIPs}
          username={username}
        />
      </Drawer>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    minWidth: '30%',
    maxWidth: '30%',
  },
});

export default UserRow;
