import { i18n } from 'common/locale';
import { useAuthentication } from 'core/authentication';
import { hasAccessToRoute } from 'core/authentication/navigationACL';
import { PageContent, Tab, useAppBarContext } from 'core/layout';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from 'sabri/components/PrivateRoute/PrivateRoute';
import { AdminRootRedirect } from './components/AdminRootRedirect';
import { AreaContextProvider } from './context';
import {
  AnomaliesPage,
  AreaManagement,
  Emergencies,
  HealthCenterManagement,
  PatientManagement,
  UnitManagement,
  UserManagement,
  UserManagementContextProvider,
} from './pages';
import ExternalTransport from './pages/ExternalTransport/ExternalTransport';
import HealthcareEnvironmentalCleaning from './pages/HealthcareEnvironmentalCleaning/HealthcareEnvironmentalCleaning';
import { Overview } from './pages/Overview/Overview';
import ThesaurusPage from './pages/Thesaurus';
import { CreateUnitDialog } from './pages/UnitManagement/dialogs/CreateUnitDialog';
import { UnitDetailsDialog } from './pages/UnitManagement/dialogs/UnitDetailsDialog';
import StepsPage from './pages/steps';
import routes from './routes';

const SuperAdmin = () => {
  const { tabs } = useSuperAdminNavigableTabs();
  const { healthCenter } = useAuthentication();

  const { setTabs, setAppBarSelect } = useAppBarContext();

  useEffect(() => {
    setAppBarSelect(undefined);
    setTabs(tabs);
  }, [tabs, setTabs, setAppBarSelect]);

  return (
    <>
      <Helmet>
        <title>{i18n.appTitle.admin}</title>
      </Helmet>
      <PageContent>
        <AreaContextProvider>
          <Routes>
            <Route
              path="areas/*"
              element={
                <PrivateRoute routeACL="admin.areas">
                  <AreaManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="units"
              element={
                <PrivateRoute routeACL="admin.units">
                  <UnitManagement />
                </PrivateRoute>
              }
            >
              <Route path="create" element={<CreateUnitDialog />} />
              <Route path=":unitId" element={<UnitDetailsDialog />} />
            </Route>
            <Route
              path="healthcenter"
              element={
                <PrivateRoute routeACL="admin.healthCenter">
                  <HealthCenterManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="users"
              element={
                <PrivateRoute routeACL="admin.users">
                  <UserManagementContextProvider>
                    <UserManagement />
                  </UserManagementContextProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="patients/*"
              element={
                <PrivateRoute routeACL="admin.patients">
                  <PatientManagement />
                </PrivateRoute>
              }
            />
            <Route
              path="external-transport"
              element={
                <PrivateRoute routeACL="admin.externalTransports">
                  <ExternalTransport />
                </PrivateRoute>
              }
            />
            <Route
              path="healthcare-environmental-cleaning"
              element={
                <PrivateRoute routeACL="admin.healthcareEnvironmentalCleaning">
                  <HealthcareEnvironmentalCleaning />
                </PrivateRoute>
              }
            />
            <Route
              path="anomalies"
              element={
                <PrivateRoute routeACL="admin.anomalies">
                  <AnomaliesPage />
                </PrivateRoute>
              }
            />
            <Route
              path="emergencies"
              element={
                <PrivateRoute routeACL="admin.emergencies">
                  <Emergencies />
                </PrivateRoute>
              }
            />
            <Route
              path="steps/*"
              element={
                <PrivateRoute routeACL="admin.appointments">
                  <StepsPage />
                </PrivateRoute>
              }
            />
            {healthCenter.thesaurusEnabled && (
              <Route
                path="thesaurus"
                element={
                  <PrivateRoute routeACL="admin.thesaurus">
                    <ThesaurusPage />
                  </PrivateRoute>
                }
              />
            )}
            <Route
              path="overview"
              element={
                <PrivateRoute routeACL="admin.overview">
                  <Overview />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<AdminRootRedirect />} />
          </Routes>
        </AreaContextProvider>
      </PageContent>
    </>
  );
};

const useSuperAdminNavigableTabs = () => {
  const { user, navigationACL, healthCenter } = useAuthentication();

  const { tabs } = useMemo(() => {
    const tabs: Record<string, Tab> = {};
    const hasNavigationACL = navigationACL.length > 0;
    const shouldIgnoreRoute = (route: string) => {
      return route === 'admin' || (route === 'admin.thesaurus' && !healthCenter.thesaurusEnabled);
    };

    if (hasNavigationACL) {
      for (const route of Object.values(routes)) {
        if (shouldIgnoreRoute(route.route)) {
          continue;
        }
        if (hasAccessToRoute(navigationACL, route.route)) {
          tabs[route.route] = {
            label: route.label,
            path: route.path,
          };
        }
      }
    } else {
      const isAdmin = ['ADMIN', 'ADMIN_REGULATOR'].includes(user.role);

      if (isAdmin) {
        for (const route of Object.values(routes)) {
          if (shouldIgnoreRoute(route.route)) {
            continue;
          }
          tabs[route.route] = {
            label: route.label,
            path: route.path,
          };
        }
      }
    }

    return { tabs };
  }, [user, navigationACL, healthCenter.thesaurusEnabled]);

  return {
    tabs: Object.values(tabs),
  };
};

export { SuperAdmin, routes as superAdminRoutes };
