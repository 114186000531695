import { Grid, Stack, Typography } from '@mui/material';
import { ListPagination } from 'common/components';
import { i18n } from 'common/locale';
import { NormalizedUser, UserUpdateForm } from 'superadmin/pages/UserManagement/context/UserManagement.model';
import { Pagination } from '../../hooks/usePagination';
import UserRow from './UserRow';
import Skeleton from './UserTableSkeleton';

type UsersTableProps = {
  users: NormalizedUser[];
  loading?: boolean;
  onUpdate: (user: UserUpdateForm) => Promise<void>;
  healthCenterId: string;
  pagination: Pagination;
};

const UsersTable: React.FC<UsersTableProps> = ({ users, loading, onUpdate, pagination, healthCenterId }) => {
  return (
    <>
      <Grid container spacing={4} direction="column">
        <Grid container item justifyContent="space-between">
          <Grid item xs={2}>
            <Typography variant="overline">{i18n.userLogin}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="overline">{i18n.userRole}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="overline">{i18n.name}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="overline">{i18n.unit}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="overline">{i18n.lastConnectionAt}</Typography>
          </Grid>
          <Grid container item xs={2} justifyContent="flex-end">
            <Typography variant="overline">{i18n.options}</Typography>
          </Grid>
        </Grid>
        <Grid container item>
          {loading ? (
            <Skeleton />
          ) : (
            <Grid container spacing={2} direction="column">
              {users.map((user) => (
                <Grid item container key={user.objectId}>
                  <UserRow {...user} onUpdate={onUpdate} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>{pagination.count} utilisateurs</Typography>
        <ListPagination
          count={pagination.count}
          onPageChange={(_, page) => pagination.setCurrentPageIndex(page)}
          onRowsPerPageChange={(e) => pagination.setPageSize(parseInt(e.target.value, 10))}
          page={pagination.currentPageIndex}
          rowsPerPage={pagination.pageSize}
          labelRowsPerPage="Utilisateurs par page"
        />
      </Stack>
    </>
  );
};

export default UsersTable;
